import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EkBharatClub/NationalUnity/1.jpg';
// import p2 from 'assests/Photos/Clubs/EkBharatClub/NationalUnity/2.jpg';
// import p3 from 'assests/Photos/Clubs/EkBharatClub/NationalUnity/3.jpg';
// import p4 from 'assests/Photos/Clubs/EkBharatClub/NationalUnity/4.jpg';
// import p5 from 'assests/Photos/Clubs/EkBharatClub/NationalUnity/5.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';

const NationalUnity2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    // const p1 = `${config.base_image_archive_url}/home/events-activities/association/EkBharatClub/DanceDrama/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/NationalUnity/2.jpg`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/NationalUnity/3.jpg`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/NationalUnity/4.jpg`;
    const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/NationalUnity/5.jpg`;

    const photos = [
        // {
        //     src: p1,
        //     source: p1,
        //     rows: 1,
        //     cols: 2,
        // },
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
      {
          src: p4,
          source: p4,
          rows: 1,
          cols: 1,
      },
      {
          src: p3,
          source: p3,
          rows: 1,
          cols: 2,
      },
        
        
        {
          src: p2,
          source: p2,
          rows: 1,
          cols: 2,
      },
     
        
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                National Unity Day
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                      Date: 31 OCTOBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur organised a unity walk to commemorate the Birth Anniversary of Sardar Vallabhbai Patel which is observed as National Unity Day. The students pledged in the school, to stay united as a nation and marched in the nearby locality of the school chanting slogans while holding placards and encouraging people to be united. This walk showcased our commitment to unity in diversity, echoing the ideals of Sardar Vallabhbai Patel.
                                    <br></br>
                                    The students took part in the walk with great enthusiasm and understood the importance of unity for the country to progress. Through this initiative students displayed their understanding of national integration, fostering harmony and respect among all. The Unity Walk emphasised the essence of our diverse heritage while emphasising the importance of unity. It was a powerful testament to our school’s dedication to nurturing responsible citizens of tomorrow.
                                   
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ONE NATION, ONE VISION, ONE INDIA!”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {/* <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> */}


                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default NationalUnity2023;